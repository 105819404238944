.card {
  background-color: var(--background-2);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 1rem;
  border: 1px solid var(--background-2);
}

.card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card__id {
  color: var(--text-2);
  font-weight: 600;
  font-size: 0.875rem;
}

.card__main {
  display: flex;
  align-items: start;
  gap: 0.25rem;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
}

.card__main img {
  margin-top: 0.125rem;
}

.card__title {
  margin: 0;
  /* margin-top: 0.5rem;
  margin-bottom: 0.75rem; */
  font-weight: 600;
  font-size: 1rem;
}

.card__footer {
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
}
