.profile-chip__image-wrapper {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.725rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: var(--background-1); */
  border: 1px solid grey;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  position: relative;
  overflow: visible;
  color: var(--background-2);
  font-weight: 600;
}

.profile-chip__image-wrapper--orange {
  background-color: darkorange;
}

.profile-chip__image-wrapper--olive {
  background-color: olive;
}

.profile-chip__image-wrapper--blue {
  background-color: rgb(85, 85, 192);
}

.profile-chip__status {
  position: absolute;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  background-color: var(--text-2);
  z-index: 1;
  bottom: -3px;
  right: -3px;
  border: 1px solid var(--background-2);
}

.profile-chip__status--available {
  background-color: green;
}

.profile-chip__status--unavailable {
  background-color: grey;
}
