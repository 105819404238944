.column {
  background-color: transparent;
  min-width: 350px;
  max-width: 400px;
  padding: 0.5rem;
  /* TODO: comment out the following line */
  /* outline: 1px solid red; */
}

.column__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.column__info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.column__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.column__title {
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-1);
  text-transform: capitalize;
}

.column__task-count {
  font-size: 0.875rem;
  color: var(--text-2);
}

.column__action-wrapper {
  display: flex;
  align-items: center;
  color: var(--text-1);
  /* gap: 0.5rem; */
}

.column__action-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--text-2);
  height: 2.5rem;
  width: 2.5rem;
}

.column__action-button img {
  width: 70%;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
}

.column__task-list {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
