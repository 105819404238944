.App {
  background-color: var(--background-1);
  min-height: 100vh;
}

.loader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.loader img {
  width: 50px;
  height: auto;
}

.navbar {
  background-color: var(--background-2);
  padding: 1rem;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
  position: relative;
}

.navbar__menu-button {
  background-color: transparent;
  border: 1px solid var(--text-2);
  border-radius: 0.25rem;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem;
  color: var(--text-1);
  font-weight: 600;
  font-size: 0.9rem;
  height: 2rem;
}

.display-options {
  position: absolute;
  top: 3.5rem;
  background-color: var(--background-1);
  border: 1px solid var(--text-2);
  border-radius: 0.25rem;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  width: 200px;
  z-index: 20;
}

.display-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.display-option__title {
  margin: 0;
  color: var(--text-1);
  font-size: 0.875rem;
  font-weight: 600;
}

.col-wrapper {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  overflow-y: scroll;
  min-height: 85vh;
}

/* Hide scrollbar */
/* .col-wrapper::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
/* .col-wrapper {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
} */
