.dropdown {
  position: relative;
  width: 40%;
}

.dropdown__button {
  font-weight: 600;
  text-transform: capitalize;
  color: var(--text-1);
  background-color: var(--background-2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--text-2);
  border-radius: 0.25rem;
  width: 100%;
  padding: 0 0.5rem;
  height: 2rem;
}

.dropdown__options {
  position: absolute;
  top: 1.5rem;
  background-color: var(--background-1);
  border-radius: 0.25rem;
  border: 0.5px solid var(--text-2);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 20;
}

.dropdown__option {
  height: 2rem;
  border-bottom: 1px solid var(--text-1);
  border: none;
  width: 100%;
  text-align: left;
  text-transform: capitalize;
}
