.tag {
  border: 1px solid var(--text-2);
  color: var(--text-1);
  width: fit-content;
  padding: 0.25rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.tag__icon-wrapper {
  width: 0.75rem;
  height: 0.75rem;
  background-color: var(--text-2);
  border-radius: 50%;
}
